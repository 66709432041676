jQuery('.hero-slider').slick({
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    fade: true,
    cssEase: 'linear',
    responsive: [
        {
            breakpoint: 991,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 640,
            settings: {
                arrows: false,
                focusOnSelect: true,
                centerMode: false,
                centerPadding: '0px',
                slidesToShow: 1
            }
        }
    ]
});


/*--- clients-projects ---*/
/*var $status = $('.pagingInfo');
var $slickElement = $('.clients-slider');

$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
  var i = (currentSlide ? currentSlide : 0) + 1;
  $status.html(i + '<span>/' + slick.slideCount + '</span>');
});*/

jQuery('.clients-slider').slick({
    rtl: false,
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 800,
    touchMove: true,
    swipe: true,
    swipeToSlide: true,

    responsive: [
        {
            breakpoint:1300,
            settings: {
                slidesToShow: 3,
                variableWidth: true,
             
            }
        },
        {
            breakpoint:991,
            settings: {
                slidesToShow: 2,
                variableWidth: true,
             
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                variableWidth: false,
                arrows: false
            }
        }
    ]
});

/*---teamSlider---*/


  
/*$('#range').on('input', function(){
    var position = document.getElementById("range").value;
    $('.teamSlider').slick('slickGoTo', position)
    console.log(position)
}); */


$('.teamSlider').on('init reInit',function(event,slick){
  var amount = slick.slideCount;
  $('#range').attr('max',amount);
})

$('.teamSlider').on('afterChange',function(e,slick,currentSlide){
  $('#range').val(currentSlide+1);
})

$('#range').on('input change',function(){
  $('.teamSlider').slick('slickGoTo',this.value-1);
});

jQuery('.teamSlider').slick({
    rtl: false,
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 800,
    touchMove: true,
    swipe: true,
    swipeToSlide: true,
    focusOnSelect: true,

    responsive: [
        {
            breakpoint:991,
            settings: {
                slidesToShow: 3
                //variableWidth: true,
             
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
                //variableWidth: false
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1
                //variableWidth: false
            }
        }
    ]
});
/*---/teamSlider---*/

jQuery('.slider-center').slick({
    dots: true,
    arrows: false,
    touchMove: true,
    swipe: true,
    speed: 800,
    easing: "linear",
    responsive: [
        {
            breakpoint:991,
            settings: {
                slidesToShow: 2,
                variableWidth: true
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                variableWidth: false
            }
        }
    ]
});



/*--- clients_slider ---*/
$('.clients_slider').on('init reInit',function(event,slick){
  var amount = slick.slideCount;
  $('#range2').attr('max',amount);
})

$('.clients_slider').on('afterChange',function(e,slick,currentSlide){
  $('#range2').val(currentSlide+1);
})

$('#range2').on('input change',function(){
  $('.clients_slider').slick('slickGoTo',this.value-1);
});
jQuery('.clients_slider').slick({
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 700,
    touchMove: true,
    swipe: true,
    swipeToSlide: true,
    //variableWidth: true,
    responsive: [
        {
            breakpoint:1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            }
        },
        {
            breakpoint:991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                //variableWidth: false,
            }
        }
    ]
});
/*--- /clients_slider ---*/

(function($){
    jQuery.fn.checkEmpty = function() {
       return !$.trim(this.html()).length;
    };
}(jQuery));

jQuery(document).ready(function ($) {
  
    $(".close-button").on("click", function () {
        $('.header_mobile_menu').toggleClass("ohide");
    })

    var slideIndex;
    $('#project-interfaces .btn-popup').click(function(e){
    e.preventDefault();
    slideIndex = $(this).attr("data-slick-index");
    $('.prjSlider').slick('slickGoTo', + slideIndex);
    });

    jQuery('#project-interfaces').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchMove: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    variableWidth: false,
                    focusOnSelect: true,
                    centerPadding: '40px'
                }
            }
        ]
    });


    $('.prjSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        fade: true,
        asNavFor: '.prjSliderNav',
        autoplay: false,
        touchMove: false,
        swipe: false,
        swipeToSlide: true,
        responsive: [
        {
            breakpoint:1200,
            settings: {
                arrows: false,
                dots: true,
            }
        }
        ]
    });
    $('.prjSliderNav').slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        asNavFor: '.prjSlider',
        dots: false,
        variableWidth: true,
        speed: 800,
        arrows: false,
        infinite: false,
        //centerMode: true,
        focusOnSelect: true,
        autoplay: false,
        responsive: [
        {
            breakpoint:640,
            settings: {
                slidesToShow: 3,
                //infinite: true,
                slidesToScroll: 1,
                arrows: true,
                centerMode: true,
                touchMove: true,
                swipe: true,
                swipeToSlide: true
            }
        }
        ]
    });
    

    /*--- rescue page ---*/
        
        

if ($(window).width() < 768) {
    $(".toggleShow").on("click", function (e) {
        e.preventDefault();
        var $this = $(this);

            if ($this.hasClass("show")) {
                $(".dark-theme").slideUp(400);
                $(".toggleShow").removeClass("show");
            } else {
                $(".toggleShow").removeClass("show");
                $this.addClass("show");
                $(".dark-theme").slideUp(400);
                $this.next('.dark-theme').slideDown(400);
            }

	});
};

    
	/*--- /rescue page ---*/



    if ($('.project_testimonial .body').checkEmpty()) {
        $('.project_testimonial').addClass('empty');

    }

    if ($('.project_description ul').checkEmpty()) {
        $('.project_description').addClass('empty');

    }
});



jQuery(document).ready(function ($) {
    $('.navbar-toggle').on("click", function () {
        $('.header_mobile_menu').toggleClass("ohide");
    })
    $(".close-menu").on("click", function () {
        $('.header_mobile_menu').toggleClass("ohide");
    })
    $(".expertise__list li").on("click", function () {
        let expertiseVal = $(this).data("expertise");
        console.log(expertiseVal);
        $('#' + expertiseVal).toggleClass("ohide");
    });
    $(".order-c").on("click", function () {
        $(".modal").toggleClass("ohide");
    });
    $(".close").on("click", function () {
        $(this).parent().parent().toggleClass("ohide");
    });
  
  $('body').on('click','.project_testimonial .company_review .more',function () {
    $('.single__project .project_testimonial .company_review').toggleClass('open');
  });
  
  var heightReview = $('.company_review .body').height();

  if ( heightReview > 230){
    $('.company_review .body').addClass('show-arrow');
  }
  
    /*--- Contact Form ---*/
    
    var inputTel = $('input[type="tel"]');

    inputTel.intlTelInput({
        
        geoIpLookup: function (callback) {
            $.get("https://ipinfo.io", function () { }, "jsonp").always(function (resp) {
                var countryCode = (resp && resp.country) ? resp.country : "";
                callback(countryCode);
            });
        },
        //hiddenInput: "full_number",
        initialCountry: "auto",
        preferredCountries: ['ua'],
        excludeCountries: ["ru"],
        separateDialCode: true,
        //utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.14/js/utils.js"
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/javascript.util/0.12.11/javascript.util.min.js"
        
    });

    inputTel.bind("change keyup input click", function() {
		if (this.value.match(/[^0-9\+]/g)) {
			this.value = this.value.replace(/[^0-9\+]/g, '');
			
			//console.log("cons"); 
			
		} 
	});
    
    /*--- /Contact Form ---*/
  

  $("#contact-form").submit(function (e) {

    e.preventDefault(); // stopping submitting
    
        var data = $(this).serializeArray();
        var url = $(this).attr('action');
        
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: data
        })
        .done(function(response) {
            console.log(response);
            if (response.success == true) {
                console.log(response.message);
                // alert("Wow you send");
                $("#contact-form")[0].reset();
            }
        })
        .fail(function () {
           
          console.log("error", "color:#B11116"); 
         
        });
    });



    /*--- Anchor scroll ---*/
	$(function() {
        $('.smooth-scroll').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                scrollTop: target.offset().top - 100
                }, 1000);
                return false;
            }
            }
        });
    });
    /*--- /Anchor scroll ---*/
    

/*--- Popup ---*/
	var player = null;
	var tag = document.createElement("script");
	tag.id = "iframe-api";
	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName("script")[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	function onYouTubeIframeAPIReady() {
	  player = new YT.Player("existing-iframe-example");
	}

	var elPopupClose = $(".popup__close");
	var elPopupOverlay = $(".popup__overlay");
    var elPopupToggle = $(".btn-popup");
    var elBody = $("body");

	function popupDidClose() {
	  if (player !== null) {
		player.pauseVideo();
	  }
	}

	elPopupClose.click(function () {
	  elPopupOverlay.css({ display: "none", visibility: "hidden", opacity: 0 });
	  popupDidClose();
      elBody.removeClass("no-scroll");
	});

	elPopupOverlay.click(function (event) {
	  event = event || window.event;
	  if (event.target === this) {
		elPopupOverlay.css({ display: "none", visibility: "hidden", opacity: 0 });
		popupDidClose();
        elBody.removeClass("no-scroll");
	  }
	});

	elPopupToggle.click(function () {
        elPopupOverlay.css({ display: "block", visibility: "visible", opacity: 1 });
        elBody.addClass("no-scroll");
	});
	/*--- /Popup ---*/
});











